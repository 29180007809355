<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6>Subscription (Pro)</h6>
					</div>
					<div class="card-body">
						<form v-on:submit.prevent="onSubmit">
							<div class="row">
								<div v-for="(data, index) in arrData" :key="data.id" class="col-md-3 mb-3">
									<div class="form-group row">
										<label for="inputHotdels" class="col-sm-2 col-form-label"></label>
										<div class="col-sm-10">
											<div class="form-check">
												<div>
													<input type="checkbox" class="form-check-input"  v-model="data.status" true-value="1" false-value="0" @change="updateContentRequest(data)">
													<label for="inputHotdels" class="form-check-label">{{ data.group }} / {{ data.name }}</label>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">Submit</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'permissionEdit',
		metaInfo: {
            title: "Permission Edit",
            titleTemplate: "%s ← SHRE Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				currentImage: "",
				image: "",
				arrData: [],
				filter: {
					page: "",
                    orderBy: "",
                    sortKey: "",
                    name: "",
				},
				contentRequest: {
					arrData: []
				},
				validationData: {
					name: ""
				}
			}
		},
		watch: {
			async $route(to, from) {
			},
		},
		computed: {},
		methods: {
			...mapActions({
				permissionUpdateAction: 'permissionUpdateAction',
				permissionDetailAction: 'permissionDetailAction'
			}),
			updateContentRequest(data) {
				const index = this.contentRequest.arrData.findIndex(item => item.id === data.id);
				if (index !== -1) {
					// Update status if already exists
					this.contentRequest.arrData[index].status = data.status;
				} else {
					// Add new entry
					this.contentRequest.arrData.push({ id: data.id, status: data.status });
				}
			},
			async getFields(){
				let option = {
				}
				await this.permissionDetailAction({
					...option
				}).then(res => {
					this.arrData = res.data.data
					this.isLoading = false
				}).catch(err => this.isLoading = true)
			},
			async onSubmit() {
				console.log(this.contentRequest.arrData)
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}

				let option = this.contentRequest
				await this.permissionUpdateAction({
					...option
				}).then(res => {
					this.$router.replace({ path: 'subscription', query: { page: 1, reverse: 'desc', sort: 'created_at', name: ''} }).catch(()=>{})
					this.isLoading = false;
				}).catch(err => this.isLoading = true)

			},
			back() {
				this.$router.replace({ path: 'subscription', query: { page: this.filter.page, orderBy: this.filter.orderBy, sortKey: this.filter.sortKey, name: this.filter.name} }).catch(()=>{})
			},
			validationRule() {
				let isValidation = []

				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
			this.filter.page = this.$route.query.page
            this.filter.orderBy = this.$route.query.orderBy
            this.filter.sortKey = this.$route.query.sortKey
			this.contentRequest.id = this.$route.query.id
			this.filter.name = this.$route.query.name
			this.getFields()
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>